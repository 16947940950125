:root {
  --blood-red: #660000;
  --blue: #4e73df;
  --blue-dark: #002072;
  --dark-teal: #4ecdc4;
  --dark-teal-hover: #029096;
  --blue-light: #add8e6;
  --button-blue-hover: #6679aa;
  --button-yellow: #f6d43e;
  --button-yellow-hover: #f9e178;
  --button-red: #e95c4f;
  --button-red-hover: #f3a59d;
  --cyan: #36b9cc;
  --danger: #e74a3b;
  --dark: #5a5c69;
  --gray: #858796;
  --gray-dark: #5a5c69;
  --gray-light: #d3d3d3;
  --green: #1cc88a;
  --indigo: #6610f2;
  --info: #36b9cc;
  --light: #f8f9fc;
  --pink: #e83e8c;
  --primary: #4e73df;
  --purple: #6f42c1;
  --red: #e74a3b;
  --secondary: #858796;
  --success: #1cc88a;
  --success2: #5cb85c;
  --teal: #20c9a6;
  --orange: #fd7e14;
  --yellow: #f6c23e;
  --warning: #f6c23e;
  --whatsapp: #128c7e;
  --white: #fff;
  --breakpoint-xs: 0;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1200px;
  --font-family-sans-serif: "Nunito", -apple-system, BlinkMacSystemFont,
    "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas,
    "Liberation Mono", "Courier New", monospace;
}

a {
  text-decoration: none !important;
  color: var(--blue-dark) !important;
}
a:link {
  text-decoration: none !important;
  color: var(--blue-dark) !important;
}
a:hover {
  text-decoration: none !important;
  color: var(--blue-dark) !important;
}
a:active {
  text-decoration: none !important;
  color: var(--blue-dark) !important;
}

body {
  margin: 0;
  font-family: "Nunito", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji",
    "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 0.9rem;
  font-weight: 400;
  line-height: 1.5;
  color: #858796;
  text-align: left;
  background-color: #fff;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  color: var(--blue-dark);
}

.m-4-override {
  margin: 1rem !important;
}

.accordion-button {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  padding: 0.5rem 1rem;
  font-size: 0.9rem;
  color: var(--blue-dark);
  text-align: left;
  background-color: #fff;
  border: 0;
  border-radius: 0;
  overflow-anchor: none;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out,
    border-radius 0.15s ease;
}

.accordion-body {
  padding: 0.5rem 0.2rem;
}

.accordion-heading-text {
  font-size: 1.1rem;
}
.accordion-heading-text-stats {
  font-size: 1rem;
}

.accord-set-padding {
  padding-right: 0px;
  padding-left: 0px;
  margin-left: 0;
  margin-right: 0;
}

.align-vert {
  display: flex;
  flex-direction: column;
}
.badgePadding {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-right: 0.5rem;
  padding-left: 0.5rem;
}
.badge-height {
  height: 20px;
}

.border-radius-4 {
  border-radius: 25px;
}
.border-radius-5 {
  border-radius: 30px;
}
.border-none {
  border: 0;
}

.button-align {
  display: flex;
  justify-content: flex-start;
}

.button-align-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.buttons-align {
  display: flex;
}
.button-align-end {
  display: flex;
  justify-content: flex-end;
}
.button-comment {
  height: 25px;
}

.button-width-100 {
  width: 100%;
}
.button-width-fit {
  width: fit-content;
}

.button-200 {
  width: 200;
}

.button-center {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.button-padding {
  padding: 2px 2px;
}
.button-padding-lg {
  padding: 10px 10px 10px 10px;
}
.button-margin-top {
  margin-right: 10px;
}
.button-margin-lr {
  margin-right: 4px;
  margin-left: 4px;
}
.button-margin-all-10 {
  margin-right: 10px;
  margin-left: 10px;
  margin-top: 10px;
  margin-bottom: 10px;
}
.button-margin-clear {
  margin: 0px;
}
.button-padding-clear {
  padding: 0px;
}
.button-search-align {
  justify-content: space-between;
}
.button-entity-users {
  display: "flex";
  justify-content: "center";
  align-items: "center";
  border-radius: 15;
  height: 65;
  margin-bottom: 5;
  margin-top: 5;
  width: 60;
}
.button-color-dashboard {
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.btn-primary,
.btn-primary:active,
.btn-primary:visited {
  background-color: var(--blue-dark) !important;
  border-color: var(--blue-dark) !important;
}

.btn-primary:hover {
  background-color: var(--button-blue-hover) !important;
  border-color: var(--button-blue-hover) !important;
}

.btn-warning,
.btn-warning:active,
.btn-warning:visited {
  background-color: var(--button-yellow) !important;
  border-color: var(--button-yellow) !important;
}

.btn-warning:hover {
  background-color: var(--button-yellow-hover) !important;
  border-color: var(--button-yellow-hover) !important;
}

.btn-secondry-green,
.btn-secondry-green:active,
.btn-secondry-green:visited {
  background-color: var(--dark-teal) !important;
  border-color: var(--dark-teal) !important;
}

.btn-secondry-green:hover {
  background-color: var(--dark-teal-hover) !important;
  border-color: var(--dark-teal-hover) !important;
}

.btn-warning,
.btn-warning:active,
.btn-warning:visited {
  background-color: var(--button-yellow) !important;
  border-color: var(--button-yellow) !important;
}

.btn-warning:hover {
  background-color: var(--button-yellow-hover) !important;
  border-color: var(--button-yellow-hover) !important;
}

.group-center {
  margin: 0px;
  width: 100%;
}
.callout-buttons-padding {
  padding-top: 10px;
}
.card-align-race {
  display: flex;
  justify-content: center;
}
.card-align-accordian {
  display: flex;
  justify-content: left;
}
.card-pad-accordian {
  margin-right: 10px;
}
.card-bottom-margin {
  margin-bottom: 10px;
}

.card-race-heading-sm {
  color: var(--blue-dark);
  font-size: 15px;
  font-weight: 500;
  margin-right: 4px;
}
.card-passenger-heading {
  color: var(--blue-dark);
  font-size: 25px;
  font-weight: 500;
  margin-right: 4px;
}
.card-passenger-sm {
  color: var(--blue-dark);
  font-size: 15px;
}
.card-vehicle-heading-lg {
  color: var(--blue-dark);
  font-size: 20px;
  font-weight: 500;
  margin-right: 4px;
}
.card-vehicle-heading {
  color: var(--blue-dark);
  font-size: 15px;
  font-weight: 500;
  margin-right: 4px;
}
.card-entity-stats-heading {
  color: var(--blue-dark);
  font-size: 15px;
  font-weight: 400;
  margin-right: 4px;
}
.card-entity-stats-heading-18 {
  color: var(--blue-dark);
  font-size: 18px;
  font-weight: 400;
  margin-right: 4px;
}
.card-entity-stats-ticket-text {
  color: var(--blue-dark);
  font-size: 14px;
  font-weight: 400;
  margin-right: 4px;
}
.card-entity-stats-ticket-text-RD {
  color: var(--white);
  font-size: 14px;
  font-weight: 400;
  margin-right: 4px;
}

.card-heading-align-left {
  display: flex;
  width: 100%;
  justify-content: left;
  margin-left: 3px;
}
.card-vehicle-heading-nc {
  font-size: 12px;
  font-weight: 300;
  margin-right: 4px;
}
.card-vehicle-text-green {
  color: var(--whatsapp);
  font-size: 15px;
}
.card-vehicle-text-red {
  color: var(--danger);
  font-size: 15px;
}
.card-vehicle-text-yellow {
  color: var(--button-yellow);
  font-size: 15px;
}
.card-vehicle-wheel-grey {
  color: var(--gray);
}
.card {
  border-radius: 10px;
}

.cardHeading {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}

.card-header:first-child {
  border-radius: calc(0.4rem - 1px) calc(0.4rem - 1px) 0 0;
}

.card-group {
  min-width: 400px;
  max-width: 400px;

  margin-top: 10px;
  margin-bottom: 10px;
}
.card-subtext {
  font-size: 15px;
  font-weight: 400;
  color: #858796;
  margin-right: 10px;
  align-self: center;
}

.card-rowHeader {
  font-size: 18px;
  color: var(--blue-dark);
}
.card-rowtext {
  font-size: 15px;
  color: #858796;
}

.card-group-display {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
}

.card-width-race {
  min-width: 300px;
  max-width: 350px;
}

.card-width-race-group {
  min-width: 350px;
  max-width: 400px;
}

.card-race-display {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
}

.card-margin-race {
  margin-top: 10px;
  margin-bottom: 10px;
  margin-right: 10px;
  margin-left: 10px;
}

.card-heading-text {
  color: grey !important;
}

.card-enity-user-width {
  width: 200;
  display: flex;
  justify-content: center;
  align-items: center;
}

.card-details-height {
  height: 300px;
  overflow-y: scroll;
}

.card-entity-user-passenger {
  width: 150px;
  /* display: flex;
  justify-content: center;
  align-items: center; */
}
.card-icon-text {
  margin-bottom: 5px;
  text-align: center;
}
.card-width-100 {
  width: 100px;
}
.card-width-vehicle {
  min-width: 300px;
  max-width: 300px;
}
.card-width-vehicle-320 {
  min-width: 320px;
  max-width: 320px;
}
.card-width-entity-stats {
  min-width: 410px;
  max-width: 410px;
}

.center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.center-left {
  display: flex;
  justify-content: left;
  align-items: center;
}

.center-left2 {
  display: flex;
  flex-direction: column;
  width: fit-content;
  /* justify-content: left;
  align-items: left; */
}

.centerButtons {
  margin: 0 auto;
  text-align: right;
  justify-content: center;
  align-items: center;
}

.center-vertical-components {
  text-align: left;
  vertical-align: middle;
}
.center-horizontal-components {
  text-align: center;
  vertical-align: middle;
}

.clickable {
  cursor: pointer;
}
.comment-subtext {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  font-size: 0.8rem;
}
.comment-body {
  padding: 10px;
  background-image: url("../images/socialm.jpg");
}
.comment-row {
  padding: 10px;
  margin-bottom: 10px;
  width: fit-content;
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
}
.comment-row-text {
  margin-right: 5px;
}
.commment-input-buttons {
  width: 100%;
  margin: 0px;
  padding: 0px;
}
.comment-zero-marg-padd {
  margin-left: 0;
  margin-right: 0;
  padding-left: 0;
  padding-right: 0;
}
.comment-time {
  font-size: 0.6rem;
  position: relative;
  top: 8px;
  left: 5px;
}

.container-top {
  margin-top: 50px;
}
.container-top-60 {
  margin-top: 60px;
}

.container-top-70 {
  margin-top: 70px;
}

.container-top-80 {
  margin-top: 80px;
}

.container-top-sm {
  margin-top: 20px;
}
.comment-counter-space {
  margin-left: 5px;
}
.custom-button {
  justify-content: center;
  align-items: center;
  border-radius: 15;
  height: 55;
  margin-bottom: 5;
  margin-top: 5;
  width: 60;
}
.dashboard-bottom-margin {
  margin-bottom: 15px;
}
.dashboard-brad {
  border-radius: 15px;
}
.dashboard-stage {
  text-align: center;
}
.dashboard-race-entity-text-200 {
  width: 200px;
}
.dashboard-race-entity-text-180 {
  width: 180px;
}
.dashboard-race-entity-text-100 {
  width: 100px;
}
.dashboard-race-entity-text-50 {
  width: 60px;
}
.dashboard-stage-card {
  flex: 1;
  flex-direction: row;
  justify-content: center;
  margin-left: 2px;
  margin-right: 2px;
  width: 120px;
}
.dashboard-min-width {
  min-width: 400px;
}
.details-align {
  display: flex;
  align-items: center;
  justify-content: left;
}

.details-icon {
  margin-right: 10px;
}
.details-card-ticket {
  padding: 10px;
}
.display-inline {
  display: inline-block;
}

.fit-content {
  width: fit-content;
}
.geo-row {
  flex-direction: row;
  margin-top: 15px;
}

.ellipses {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.entity-map-logo {
  color: var(--blue-dark);
  font-size: 20px;
  font-weight: 500;
  margin-right: 4px;
  margin-left: 10px;
}

.entity-table-padding {
  padding: 200;
}
.entity-splash-padding {
  margin-bottom: 10px;
}

.form-container {
  display: flex;
  justify-content: center;
}

.form-width-default {
  width: 450px;
  min-width: 300px;
  max-width: 450px;
}

.grow-column {
  flex-grow: 1;
}

.hr {
  /* margin-top: 1rem; */
  /* margin-bottom: 1rem; */
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.3);
}
.hr-width-200 {
  width: 200px;
}
.hr-width-250 {
  width: 250px;
}
.hr-width-300 {
  width: 300px;
}
.hr-width-350 {
  width: 350px;
}
.hr-width-380 {
  width: 358px;
}
.row-flex {
  display: flex;
}
.icon-align {
  display: flex;
  align-items: center;
  justify-content: right;
  font-size: 0.5rem;
}
.icon-align-center {
  display: flex;
  align-items: center;
}
.icon-padding {
  padding: 0px 10px 0px 0px;
}
.icon-stat-size {
  width: 30px;
  height: 30px;
  margin: 5px;
}
.icon-text-padding {
  padding: 0px 0px 0px 10px;
}

.icon-thumbs-color {
  color: var(--light);
}
.icon-smile-color {
  color: var(--warning);
}

.icon-whatsapp-color {
  color: var(--whatsapp);
}
.icon-joc-share-off {
  color: var(--gray);
  font-size: 1.2rem;
}
.icon-joc-share-on {
  color: var(--danger);
  font-size: 1.2rem;
}
.icon-badge {
  border: 1px solid #c0c5d4;
  padding: 2px;
  box-shadow: 1px 1px 5px rgba(15, 10, 10, 0.1);
  border-radius: 15px;
}

.icon-inner-badge {
  padding: 5px;
  border-radius: 25px;
  background-color: var(--primary);
}

.input-color {
  color: var(--dark);
  opacity: 1;
  background-color: white;
}

.input-padding {
  padding: 8px 8px 8px 8px;
}

.input-styles {
  background-color: darkgray;
}

.leftComponents {
  margin: 0 auto;
  text-align: left;
  padding: 2.5px;
  font-weight: 400;
}
.totals {
  font-weight: 400;
}

.leaflet-container {
  width: 100%;
  height: 89vh;
}

.listgroup {
  padding-top: 8px;
  padding-bottom: 15px;
}

.list-style {
  font-family: "Nunito", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji",
    "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #858796;
  text-align: left;
  background-color: #fff;
}

.mapOptions {
  /* max-height: 300px; */
  height: auto;
}

.margin-bottom-0 {
  margin-bottom: 0px;
}
.marginTop-5 {
  margin-top: 5px;
  padding-right: 0px;
}
.marginTop10 {
  margin-top: 10px;
  /* padding-right: 0px; */
}

.marginTop20 {
  margin-top: 20px;
  padding-right: 0px;
}
.marginBottom10 {
  margin-bottom: 10px;
}

.marginBottom-5 {
  margin-bottom: 5px;
}

.margin-left {
  margin-left: 10px;
}
.margin-lr5 {
  margin-left: 5px;
  margin-right: 5px;
}
.margin-lr20 {
  margin-left: 20px;
  margin-right: 20px;
}
.margin-lr30 {
  margin-left: 30px;
  margin-right: 30px;
}

.margin-override {
  margin: 0px;
}

.media-override {
  padding: 0px;
}
.media-color {
  color: var(--danger);
}

.opacity-6 {
  opacity: 0.6;
}
.opacity-4 {
  opacity: 0.4;
}
.padding-bottom-override {
  padding-bottom: 0px;
}
.p .padding-sm {
  padding-top: 20px;
}
.padding-xsm {
  padding-top: 10px;
}

.page-not-found {
  font-size: 10rem;
  font-weight: 400;
}

.page-new-search-align {
  justify-content: space-between;
}
.pagination-padding {
  margin-left: 5px;
}

.pagination > li > a {
  background-color: white;
  color: var(--blue-dark);
}

.pagination > li > a:focus,
.pagination > li > a:hover,
.pagination > li > span:focus,
.pagination > li > span:hover {
  color: #5a5a5a;
  background-color: #eee;
  border-color: #ddd;
}

.pagination > .active > a {
  color: white;
  background-color: var(--gray-light) !important;
  border: solid 1px var(--blue-dark) !important;
}

.pagination > .active > a:hover {
  background-color: var(--gray) !important;
  border: solid 1px var(--button-blue-hover);
}

.row-fit {
  width: fit-content;
}
.scroller {
  height: fit-content;
  max-height: 300px;
  overflow-y: scroll;
  scrollbar-color: var(--blue-dark) var(--button-blue-hover);
  scrollbar-width: thin;
}
.scroller-note {
  border-radius: 30px;
  height: fit-content;
  max-height: 512px;
  overflow-y: scroll;

  scrollbar-color: var(--blue-dark) var(--button-blue-hover);
  scrollbar-width: thin;
}
.shadow {
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
}

.searchBar-width-300 {
  width: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.social-text {
  font-size: 0.8rem;
  font-weight: 400;
  padding-left: 4px;
  padding-right: 4px;
}

.splash-align {
  display: flex;
  justify-content: left;
}
.splash-align-padding {
  margin-right: 1rem;
}
.table-heading {
  color: grey !important;
}

.table-group {
  width: 380px;
  max-width: 380px;
}

.table-login {
  width: 380px;
  max-width: 380px;
}

.table {
  /* margin-bottom: 1rem; */
  color: #858796;
}
.row-padding {
  padding: 5px 5px;
}
.table th,
.table td {
  padding: 0.4rem;
  vertical-align: middle;
  border-top: 1px solid #e3e6f0;
  height: 35px;
}

.table thead th {
  vertical-align: bottom;
  border-bottom: 2px solid #e3e6f0;
}

.table tbody + tbody {
  border-top: 2px solid #e3e6f0;
}

.table-top-padding {
  margin-top: 10px;
}

.text-blue {
  color: var(--blue);
}
.text-darkblue {
  color: var(--blue-dark);
}
/* his has a media Query NB*/
.textarea-width {
  width: 100%;
}

.ticketHeading {
  font-size: 20px;
  font-weight: 600;
  color: var(--blue-dark);
}
.ticket-min-size {
  min-width: 460px;
}
.ticket-rhs-clear {
  margin-right: 0px;
  margin-left: 0px;
  padding-left: 0px;
  padding-right: 0px;
}

.toggle-shadow {
  box-shadow: 1px 1px 5px rgba(15, 10, 10, 0.1);
}

.overwrite-row-gutter {
  --bs-gutter-x: 0.05rem;
}
justify-content .overwrite-row-padding {
  padding-right: calc(var(--bs-gutter-x) * 0.1);
}

.ticketCard {
  width: 480;
  margin: 15;
}

.tickets {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding-top: 13px;
}

@media screen and (min-width: 992px) {
  .table-group {
    width: 550px;
    max-width: 550px;
  }
}

/* This is the CSS for the comment  section of the cards */

.content-item {
  /* padding: 30px 0; */
  background-color: #ffffff;
  width: 100%;
}

.content-item.grey {
  background-color: #f0f0f0;
  padding: 10px 0;
  height: 100%;
}

.content-item h2 {
  font-weight: 700;
  font-size: 35px;
  line-height: 45px;
  text-transform: uppercase;
  margin: 10px 0;
}

.content-item h3 {
  font-weight: 400;
  font-size: 20px;
  color: #555555;
  margin: 10px 0 15px;
  padding: 0;
}
justify-content .content-headline {
  height: 1px;
  text-align: center;
  margin: 10px 0 70px;
}

.content-headline h2 {
  background-color: #ffffff;
  display: inline-block;
  margin: -20px auto 0;
  padding: 0 10px;
}

.grey .content-headline h2 {
  background-color: #f0f0f0;
}

.content-headline h3 {
  font-size: 14px;
  color: #aaaaaa;
  display: block;
}

.comments {
  background-color: #ffffff;
}

.media {
  border-top: 1px dashed #dddddd;
  padding: 10px 0;
  margin: 0;
}

.note-avatar {
  border: 3px solid;
  width: 45px;
  height: 45px;
  background: #e6e7ed;
  border-radius: 50px;
  font-size: 25px;
}
.note-heading {
  text-align: left;
  font-size: 18px;
}
.note-text {
  text-align: left;
  padding-left: 12px;
}

.note-card-border {
  border-radius: 25px;
  /* min-width: 300px; */
  width: fit-content;
  padding-right: 20px;
}

.passeger-card-border {
  border-radius: 25px;
  /* min-width: 300px; */
  width: fit-content;
}

.note-avatar-box {
  -ms-flex: 0 0 50px;
  flex: 0 0 20px;
  margin-left: 8px;
}

.note-time {
  font-size: 0.6rem;
  position: relative;
  top: 8px;
  left: 5px;
}
.note-subtext {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  font-size: 0.8rem;
}
.note-text-align {
  margin-left: 5px;
  margin-bottom: 10px;
}

/* The switch - the box around the slider */
.switch {
  position: relative;
  display: inline-block;
  width: 50px;
  height: 24px;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 16px;
  width: 16px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #2196f3;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196f3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 60%;
}

.overlay {
  position: fixed;
  display: none;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  /* background-color: rgba(0, 0, 0, 0.5);\
  */
  background-color: #858796;
  z-index: 2;
  cursor: pointer;
}

.text {
  position: absolute;
  top: 50%;
  left: 50%;
  font-size: 25px;
  color: white;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
}

/* Tool Tip override */

/* .tooltip-main { */
/* width: 15px; */
/* height: 15px; */
/* border-radius: 50%; */
/* font-weight: 700; */
/* background: #f3f3f3; */
/* border: 1px solid #1cc88a; */
/* color: #737373; */
/* margin: 4px 121px 0 5px; */
/* float: right; */
/* text-align: left !important; */
/* } */

/* .tooltip-qm { */
/* float: left; */
/* margin: -2px 0px 3px 4px; */
/* font-size: 12px; */
/* border: 1px solid #1cc88a; */
/* } */

.tooltip-inner {
  /* max-width: 236px !important; */
  /* height: 76px; */
  /* font-size: 12px; */
  /* padding: 10px 15px 10px 20px; */
  background: var(--dark-teal);
  /* color: rgba(0, 0, 0, 0.7); */
  /* text-align: left; */
  color: var(--white);
}

@media (min-width: 200px) {
  .textarea-width {
    width: 80%;
  }
}
@media (min-width: 400px) {
  .textarea-width {
    width: 75%;
  }
}

@media (min-width: 768px) {
  .textarea-width {
    width: 75%;
  }
}

@media (min-width: 992px) {
  .textarea-width {
    width: 80%;
  }
}

@media (min-width: 1200px) {
  .textarea-width {
    width: 83%;
  }
}
@media (min-width: 1500px) {
  .textarea-width {
    width: 86%;
  }
}
